.wrapper {
  display: grid;
  justify-items: center;
  align-items: start;
  position: relative;
  width: 100%;
  min-width: 320px;
  min-height: calc(100vh - 57px);
  min-height: calc(100svh - 57px); /* for Safari mobile */
  background: var(--color-grayscale-white);

  @media (--portrait-tablet) {
    padding: 57px 0;
    background: transparent;
  }

  @media (--laptop) {
    padding: 86px 0;
    min-height: calc(100vh - 64px);
    min-height: calc(100svh - 64px); /* for Safari mobile */
  }
}

.container {
  display: grid;
  gap: 24px;
  position: relative;
  width: 100%;
  max-width: 588px;
  padding: 24px 16px;
  background: var(--color-grayscale-white);
  align-items: start;

  @media (--portrait-tablet) {
    padding: 56px 80px;
    border: 1px solid var(--color-grayscale-disabled-light);
    border-radius: 24px;
    box-shadow: var(--shadow-blue);
  }

  > div:empty {
    display: none;
  }
}
