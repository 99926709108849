.decorators {
  display: none;
  position: absolute;
  inset: 0;
  z-index: -1;

  @media (--desktop) {
    display: block;
  }
}

.decoratorsItem {
  position: absolute;

  &__image {
    aspect-ratio: 1;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }

  &__dotts {
    width: 74px;
    height: 74px;
    position: absolute;
    z-index: -1;
  }

  /* top left */
  &:nth-child(1) {
    --size: 173px;
    width: var(--size);
    height: var(--size);
    top: 84px;
    left: calc((var(--size) + 100px) * -1);

    .decoratorsItem__image {
      border-bottom-right-radius: initial;
    }

    .decoratorsItem__dotts {
      bottom: 0;
      right: 0;
      transform: translate(50%, -20%);
    }
  }

  /* bottom left */
  &:nth-child(2) {
    --size: 125px;
    width: var(--size);
    height: var(--size);
    top: 300px;
    left: calc((var(--size) + 64px) * -1);

    .decoratorsItem__image {
      border-top-right-radius: initial;
    }
  }

  /* top right */
  &:nth-child(3) {
    --size: 120px;
    width: var(--size);
    height: var(--size);
    top: 78px;
    right: calc((var(--size) + 118px) * -1);

    .decoratorsItem__image {
      border-bottom-left-radius: initial;
    }
  }

  /* bottom right */
  &:nth-child(4) {
    --size: 174px;
    width: var(--size);
    height: var(--size);
    top: 275px;
    right: calc((var(--size) + 64px) * -1);

    .decoratorsItem__image {
      border-top-left-radius: initial;
    }

    .decoratorsItem__dotts {
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
}
